import React from "react";
import "./style/style.css";
import Dropzone from "react-dropzone";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import { extInstanceMultipart } from "Actions/AxiosInstance";
import { bindActionCreators } from "redux";
// import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import Thumbnail from "Component/Thumbnail/ThumbnailSmall";
import { getFormattedLogoLink } from "Utils";
import sumhrlogo from "./images/sumhr-logo.svg";
import uploadicon from "./images/upload-outline-16px.svg";
import lineArrow from "./images/line-arrow-left-outline-16px.svg";

const styles = () => ({
  drawerPaper: {
    width: "30%",
  },
});

class DocumentUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postdata: this.props.postdata,
      jobdetails: this.props.jobdetails,
      // resumepath: null,
      // coverletterpath: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.uploadDoc = this.uploadDoc.bind(this);
  }

  onSubmit() {
    this.props.onScreeningquestion();
  }

  async uploadDoc(key, files) {
    const { postdata } = this.state;
    console.log("files: ", files);
    // const { resumepath, coverletterpath } = this.state;
    const formData = new FormData();
    formData.append("file", files[0]);
    // if (files.length == 0) {
    //   this.props.enqueueSnackbar("Please select Valid image. (Maxsize : 2MB)", {
    //     variant: "error",
    //   });
    //   return false;
    // }
    extInstanceMultipart
      .post(`/drive/uploaddocument`, formData)
      .then((resp) => {
        console.log("resp.data.result: ", resp);
        // if (id == 2) {
        postdata[key] = resp.data.result[0].imagepath;
        //   sectiondetail.documenttypeid =
        //     getDocTypeid[resp.data.result[0].extension];
        //   this.setState({
        //     sectiondetail,
        //   });
        // }
        // if (id == 1) {
        // [key] = resp.data.result[0].imagepath;
        // sectiondetail.documenttypeid =
        //   getDocTypeid[resp.data.result[0].extension];
        // postData.sectiondetail.push(sectiondetail);
        this.setState({
          postdata,
        });
        // }
      });
  }

  render() {
    const { postdata, jobdetails } = this.state;
    return (
      <div style={{ background: "rgb(249, 249, 249)" }}>
        <div>
          <div
            className="c-fluid-container"
            style={{ backgroundColor: "#fff", padding: "32px 0px 0px" }}
          >
            <div className="c-wrapper">
              <div className="c-margin-bottom-32">
                <img
                  style={{
                    maxHeight: 100,
                    maxWidth: 200,
                    objectFit: "contain",
                  }}
                  src={getFormattedLogoLink(
                    this.props.subscriptiondetails.logo,
                  )}
                  alt="icon"
                />
              </div>
            </div>
          </div>
          <div
            className="c-fluid-container"
            style={{ backgroundColor: "#fff", padding: "32px 0px 32px 0px" }}
          >
            <div className="c-wrapper">
              <h1
                style={{
                  color: "rgb(1, 7, 9)",
                  fontSize: 40,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                {jobdetails && jobdetails.designationname}
              </h1>
              <p
                style={{
                  color: "rgb(84, 85, 86)",
                  fontSize: 24,
                  marginRight: 16,
                  fontWeight: "bold",
                  marginTop: 0,
                }}
              >
                {jobdetails &&
                  jobdetails.location &&
                  jobdetails.location.location}{" "}
                • {jobdetails && jobdetails.designationname} •{" "}
                {jobdetails && jobdetails.jobtype}
              </p>
            </div>
          </div>
          <div className="c-wrapper">
            <div
              className="c-fluid-container c-width100"
              style={{ background: "rgb(249, 249, 249)" }}
            >
              <div className="c-display-flex">
                <p
                  style={{
                    color: "rgb(1, 7, 9)",
                    fontSize: 24,
                    marginBottom: 32,
                    fontWeight: "bold",
                    marginTop: 0,
                  }}
                >
                  Step 2: Document upload
                </p>
              </div>
              <div className="c-MuiGrid-root c-margin-bottom-24 c-MuiGrid-container">
                <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-3">
                  <p className="c-normal-dark-secondary c-margin-top-8">
                    Upload resume *
                  </p>
                </div>
                <div>
                  {postdata.resumepath == null ? (
                    <Dropzone
                      onDrop={(e) => this.uploadDoc("resumepath", e)}
                      multiple={false}
                      accept="application/pdf,application/msword"
                      maxSize={2000000}
                      className="height100 drag-drop"
                    >
                      <div className="c-uploader-wrapper cursor-pointer">
                        <div className="vertical-center" style={{ height: 20 }}>
                          <img
                            src={uploadicon}
                            className="vertical-middle"
                          ></img>
                          <span className="margin-left-16 small-blue">
                            Click here to upload
                          </span>
                        </div>
                      </div>
                      <p className="input-label">
                        Note:<br></br>
                          Supported files: .pdf and msword<br></br>
                          Max size: 2MB
                      </p>
                      {/* <p
                        className="input-label"
                        style={{
                          marginBottom: 24,
                          background: "#edf5fd",
                          padding: 8,
                          color: "#1e8ced",
                          clear: "left",
                        }}
                      >
                        Note: Upload attachment with max size 2 MB for optimum
                        results. (Supports .pdf and msword file types)
                      </p> */}
                    </Dropzone>
                  ) : (
                    <div className="about-us-file-uploader">
                      <Thumbnail
                        key="key"
                        onDelete={() => this.onDeleteDoc("uploadimg")}
                        onEdit={(e) => this.uploadDoc("resumepath", e)}
                        filetypes="image/*"
                        doc={{ url: postdata.resumepath }}
                      />
                       <p className="input-label">
                        Note:<br></br>
                          Supported files: .pdf and msword<br></br>
                          Max size: 2MB
                      </p>
                      {/* <p
                        className="input-label"
                        style={{
                          marginBottom: 24,
                          background: "#edf5fd",
                          padding: 8,
                          color: "#1e8ced",
                          clear: "left",
                        }}
                      >
                        Note: Upload attachment with max size 2 MB for optimum
                        results. (Supports .pdf and msword file types)
                      </p> */}
                    </div>
                  )}
                </div>
                {/* <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                  <div
                    className="c-width100  c-drag-drop"
                    aria-disabled="false"
                    style={{ position: "relative" }}
                  > */}
                {/* <div className="c-uploader-wrapper c-cursor-pointer">
                      <div className="c-vertical-center" style={{ height: 20 }}>
                        <img
                          src={uploadicon}
                          style={{ verticalAlign: "middle" }}
                        />
                        <span
                          className="c-small-blue"
                          style={{ marginLeft: 16 }}
                        >
                          Click here to upload
                        </span>
                      </div>
                    </div> */}
                {/* <input
                      accept="image/*"
                      type="file"
                      autoComplete="off"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        opacity: 0.5,
                        pointerEvents: "none",
                      }}
                    /> */}
                {/* </div>
                </div> */}
              </div>
              <div className="c-MuiGrid-root c-MuiGrid-container margin-top-16">
                <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-3">
                  <p className="c-normal-dark-secondary c-margin-top-8">
                    Cover letter
                  </p>
                </div>
                <div className="">
                  {postdata.coverletterpath == null ? (
                    <Dropzone
                      onDrop={(e) => this.uploadDoc("coverletterpath", e)}
                      multiple={false}
                      accept="application/pdf,application/msword"
                      maxSize={2000000}
                      className="height100 drag-drop"
                    >
                      <div className="c-uploader-wrapper cursor-pointer">
                        <div className="vertical-center" style={{ height: 20 }}>
                          <img
                            src={uploadicon}
                            className="vertical-middle"
                          ></img>
                          <span className="margin-left-16 small-blue">
                            Click here to upload
                          </span>
                        </div>
                      </div>
                      <p className="input-label">
                        Note:<br></br>
                          Supported files: .pdf and msword<br></br>
                          Max size: 2MB
                      </p>
                      {/* <p
                        className="input-label"
                        style={{
                          marginBottom: 24,
                          background: "#edf5fd",
                          padding: 8,
                          color: "#1e8ced",
                          clear: "left",
                        }}
                      >
                        Note: Upload attachment with max size 2 MB for optimum
                        results. (Supports .pdf and msword file types)
                      </p> */}
                    </Dropzone>
                  ) : (
                    <div className="about-us-file-uploader">
                      <Thumbnail
                        key="key"
                        onDelete={() => this.onDeleteDoc("uploadimg")}
                        onEdit={(e) => this.uploadDoc("coverletterpath", e)}
                        filetypes="image/*"
                        doc={{ url: postdata.coverletterpath }}
                      />
                       <p className="input-label">
                        Note:<br></br>
                          Supported files: .pdf and msword<br></br>
                          Max size: 2MB
                      </p>
                      {/* <p
                        className="input-label"
                        style={{
                          marginBottom: 24,
                          background: "#edf5fd",
                          padding: 8,
                          color: "#1e8ced",
                          clear: "left",
                        }}
                      >
                        Note: Upload attachment with max size 2 MB for optimum
                        results. (Supports .pdf and msword file types)
                      </p> */}
                    </div>
                  )}
                </div>
                {/* <div className="c-MuiGrid-root c-MuiGrid-item c-MuiGrid-grid-xs-12 c-MuiGrid-grid-sm-12 c-MuiGrid-grid-md-4">
                  <div
                    className="c-width100  c-drag-drop"
                    aria-disabled="false"
                    style={{ position: "relative" }}
                  > */}
                {/* <div className="c-uploader-wrapper c-cursor-pointer">
                      <div className="c-vertical-center" style={{ height: 20 }}>
                        <img
                          src={uploadicon}
                          style={{ verticalAlign: "middle" }}
                        />
                        <span
                          className="c-small-blue"
                          style={{ marginLeft: 16 }}
                        >
                          Click here to upload
                        </span>
                      </div>
                    </div> */}
                {/* <input
                      accept="image/*"
                      type="file"
                      autoComplete="off"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        opacity: 0.5,
                        pointerEvents: "none",
                      }}
                    /> */}
                {/* </div>
                </div> */}
              </div>
              <div className="c-margin-top-24">
                <div
                  style={{
                    float: "left",
                    marginBottom: 16,
                    cursor: "pointer",
                  }}
                  onClick={() => this.props.onPersonaldetail()}
                >
                  <span style={{ marginRight: 16 }}>
                    <img src={lineArrow} />
                  </span>
                  <span
                    className="c-normal-dark-secondary"
                    style={{ fontWeight: "bold" }}
                  >
                    Personal details
                  </span>
                  <div className="c-clear-float"></div>
                </div>
                <div style={{ float: "right" }}>
                  <button
                    className="c-btn c-btn-primary-md-blue"
                    type="button"
                    onClick={() => this.props.onScreeningquestion()}
                  >
                    Next step
                  </button>
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            </div>
          </div>
          {/* <div
            className="c-wrapper"
            style={{
              background: "rgb(249, 249, 249)",
              padding: "88px 0",
              textAlign: "center",
            }}
          >
            <p className="c-normal-dark">Powered by</p>
            <img src={sumhrlogo} />
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  SubscriptionData: state.SubscriptionInfo.SubscriptionData,
  prop: state.prop,
  login: state.login,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withSnackbar(DocumentUpload)));

// export default DocumentUpload;
