import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import React from "react";
import Helmet from "react-helmet";
import PermissionDeniedComp from "Component/PermissionDeniedComp/PermissionDeniedComp";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import headersList from "../../../../../Constants/headersList";
import { instance } from "../../../../../Actions/AxiosInstance";
import { CreateComp, List, TDTypeConsts } from "../../commons";
import { getGradeList, updateRespectiveList } from "../actions";
import ActionConstants from "../constants";
import { getBusinessConfig } from "../selectors";
import "../styles/index.css";
import { CheckActionPermission } from "../../../../../Actions/commonAction";
import { ChangeLanguage } from "../../../../../Actions/LanguageAction";
import gradeConcept from "../../../../../Assets/Images/step_to_the_sun.svg";
import { isSpaceInString } from "Utils";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginRight: "10px",
  },
  typography: {},
});

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      item: {
        gradename: "",
        subscriptiongradeid: null,
      },
      permissions: { readOnly: false, editable: false },
    };
    this.headers = [
      {
        key: "graderank",
        name: <FormattedMessage id="LbRank" defaultMessage="Rank" />,
        type: TDTypeConsts.GRADE_RANK,
        style: { width: "30px", textAlign: "right" },
        extraProps: { numeric: true },
      },
      {
        key: "gradename",
        name: <FormattedMessage id="LbGradeName" defaultMessage="Grade Name" />,
        type: TDTypeConsts.GRADE_NAME,
        style: { textAlign: "left" },
      },
      {
        key: "addedbyimagepath",
        name: <FormattedMessage id="LbCreatedBy" defaultMessage="Created By" />,
        type: TDTypeConsts.CREATED_BY,
      },
      {
        key: "assignedEmployeeList",
        name: (
          <FormattedMessage
            id="LbAssignedEmployee"
            defaultMessage="Assigned Employee"
          />
        ),
        type: TDTypeConsts.ASSIGNED_USER,
      },
      { key: "edit", name: "", type: TDTypeConsts.EDIT_WITHOUT_HEADER },
      { key: "delete", name: "", type: TDTypeConsts.DELETE_WITHOUT_HEADER },
    ];

    this.handlechange = this.handlechange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.props.ChangeLanguage("grade");
    // const [isTrue, actionlistObject] = CheckActionPermission(this.props.history.location.pathname)

    const [isTrue, permissions] = CheckActionPermission("grade");
    if (isTrue) {
      this.setState({ permissions }, () => {
        this.props.getGradeList();
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.EDIT_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
        }
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.DELETE_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
          // this.headers
          // this._renderComponent()
        }
      });
    } else {
      this.setState({ permissiondenied: true });
    }
  }

  getText() {
    return (
      <span>
        <FormattedMessage
          id="gradeConcept"
          defaultMessage="Grades are a nifty option to create levels within the hierarchy structure of your organisation which is created by Designations."
        />
        <br></br>
        <br></br>
        <FormattedMessage
          id="gradeConceptSub"
          defaultMessage="Usage of grades is more from an administrative perspective and helps in grouping Designations into slabs, from lower to upper levels."
        />
      </span>
    );
  }

  _renderCreate = () => (
    <CreateComp
      text={this.getText()}
      heading={
        <FormattedMessage
          id="LbGradeConcept"
          defaultMessage="Concept of Grade"
        />
      }
      onCreate={this._onCreate}
      imageSrc={gradeConcept}
      permissions={this.state.permissions}
    />
  );

  onSave() {
    const data = this.state.item;
    const { match } = this.props;

    if (data.gradename && !isSpaceInString(data.gradename)) {
     data.gradename = data.gradename.trim();
    }

    instance
      .post("subscription/grade", {
        gradename: data.gradename,
        subscriptiongradeid: data.subscriptiongradeid,
      })
      .then(
        () => {
          const msg = data.subscriptiongradeid
            ? "Grade has successfully been updated"
            : "Grade has successfully been created";
          this.props.enqueueSnackbar(msg, { variant: "success" });
          this.props.getGradeList();
          this.props.history.push(`${match.path}`);
        },
        (error) => {
          const msg =
            error.response.data.error.message || "Something went wrong.";
          this.props.enqueueSnackbar(msg, { variant: "error" });
        },
      );
  }

  showSnackbar = (msg, variant, autoHideDuration) => {
    this.props.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: autoHideDuration || 1000,
    });
  };

  _onCreate = () => {
    const BUSINESS_DATA = {
      businessunitname: "",
      officeaddressid: null,
      businessunitheadid: null,
      businessunitid: null,
    };
    this.setState({ postData: BUSINESS_DATA }, () => this._setEditPage());
  };

  _onCreate = () => {
    this.setState({ item: [] }, () => this._setEditPage());
  };

  onEdit = (item) => {
    this.setState({ item }, () => this._setEditPage());
  };

  _setEditPage = () => {
    const { match } = this.props;
    this.props.history.push(`${match.path}/create`);
  };

  onDelete = (item) => {
    const { gradeList } = this.props;
    const { list } = gradeList;
    const id = item.subscriptiongradeid;
    const index = list.indexOf(item);
    instance.delete(`subscription/grade/${id}`).then(
      () => {
        list.splice(index, 1);
        this.showSnackbar("Grade successfully deleted.", "success");
        // this.props.updateRespectiveList(ActionConstants.GRADE, list);
        this.props.getGradeList();
        // have to reRender as custom action will not trigger re-render (reason typeToReducer)
        this.setState({ reRender: true }, () => this.props.getGradeList());
      },
      (error) => {
        const msg =
          error.response.data.error.message || "Error while deleting Grade.";
        this.showSnackbar(msg, "error", 2000);
      },
    );
  };

  _renderList = () => {
    const { gradeList } = this.props;
    this.formConfig = null;
    return (
      <List
        list={gradeList.list}
        headers={this.headers}
        headerIndex={1}
        onCreate={this._onCreate}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        module={ActionConstants.GRADE}
        goTo={this.props.goTo}
        sortOrder="desc"
        permissions={this.state.permissions}
      />
    );
  };

  showLoader() {
    return <div className="pre-loader">Loading...</div>;
  }

  _renderComponent = () => {
    const { gradeList } = this.props;
    if (gradeList.IsPending) {
      return this.showLoader();
    }
    const language = this.props.Language.grade;
    return (
      <IntlProvider locale="en" messages={language}>
        <div>
          {gradeList.list && gradeList.list.length == 0 && this._renderCreate()}
          {gradeList.list && gradeList.list.length > 0 && this._renderList()}
        </div>
      </IntlProvider>
    );
  };

  _renderForm = () => {
    const language = this.props.Language.grade;
    return (
      <IntlProvider locale="en" messages={language}>
        <div className="width50">
          <p className="form-sub-header">Grade</p>
          <div>
            <p className="form-input-label">Grade Name</p>
            <TextField
              id="outlined-dense"
              margin="dense"
              variant="outlined"
              style={{ width: "100%" }}
              placeholder="Enter grade name"
              onChange={this.handlechange}
              value={this.state.item.gradename}
              maxLength="100"
              className="form-text-input"
            />
            <Button
              onClick={this.onSave}
              className="margin-top-20"
              variant="contained"
              color="primary"
              size="large"
            >
              Save
            </Button>
          </div>
        </div>
      </IntlProvider>
    );
  };

  handlechange(e) {
    const { item } = this.state;
    item.gradename = e.target.value;
    this.setState({
      item,
    });
  }

  render() {
    const { classes, match } = this.props;
    const language = this.props.Language.grade;
    return (
      <IntlProvider locale="en" messages={language}>
        <main className="content-outer-wrapper">
          <Helmet>
            <title>{headersList.Config.Grade.header}</title>
          </Helmet>
          {this.state.permissiondenied ? (
            <div className="position-rel">
              <PermissionDeniedComp />
            </div>
          ) : (
            <div>
              <div className={classes.root}>
                <Grid container className="business-config-layout-container">
                  <Grid item xs={12} md={12} sm={12}>
                    <Switch>
                      <Route
                        exact
                        path={`${match.path}/create`}
                        render={this._renderForm}
                      />
                      <Route
                        exact
                        path={`${match.path}`}
                        render={this._renderComponent}
                      />
                      <Redirect from={`${match.path}`} to={`${match.path}`} />
                    </Switch>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </main>
      </IntlProvider>
    );
  }
}

Index.propTypes = {};
const mapStateToProps = (state) => ({
  Language: state.LanguageAction.Language,
  gradeList: getBusinessConfig(state, ActionConstants.GRADE),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGradeList,
      updateRespectiveList,
      ChangeLanguage: (data) => ChangeLanguage(data),
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withSnackbar(Index)));
