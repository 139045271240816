import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Helmet from "react-helmet";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import React from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import PermissionDeniedComp from "Component/PermissionDeniedComp/PermissionDeniedComp";
import headersList from "../../../../../Constants/headersList";
import { instance } from "../../../../../Actions/AxiosInstance";
import { CreateComp, List, TDTypeConsts } from "../../commons";
import Stepper from "../../../../../Component/Stepper";
import { getDesignationList, updateRespectiveList } from "../actions";
import ActionConstants from "../constants";
import { getBusinessConfig } from "../selectors";
import Details from "./Details";
import JobDetails from "./JobDetails";
import "../styles/index.css";
import { CheckActionPermission } from "../../../../../Actions/commonAction";
import { ChangeLanguage } from "../../../../../Actions/LanguageAction";
import DrawerHeader from "../../../../../Component/Headers/drawerHeader";
import designationConcept from "../../../../../Assets/Images/career_progress.svg";
import Popover from "@material-ui/core/Popover";
import List1 from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginRight: "10px",
  },
  typography: {},
});

const POSTDATA = {
  designationid: null,
  designationname: "",
  departmentids: null,
  issubdepartment: false,
  selectedDepartment: null,
  jobdetail: {
    mission: "",
    rolesummary: "",
    responsibility: null,
    skill: [],
  },
};
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      postData: POSTDATA,
      permissions: { readOnly: false, editable: false },
      selectedDepartment: "",
      AllStep: [],
      completed: false,
      activeStep: 0,
      allTags: [],
      allData: [],
      createmenu: false,
      create_anchorEl: null,
    };

    this.submit = this.submit.bind(this);
    this.headers = [
      {
        key: "designationname",
        name: (
          <FormattedMessage
            id="LbDesignationName"
            defaultMessage="Designation Name"
          />
        ),
        type: TDTypeConsts.DESIGNATION_NAME,
        style: { textAlign: "left" },
      },
      {
        key: "departmentcount",
        name: (
          <FormattedMessage
            id="__LbLinkedDepartments"
            defaultMessage="Linked Departments"
          />
        ),
        type: TDTypeConsts.CLICKABLE_NUMBER,
      },
      {
        key: "addedbyimagepath",
        name: <FormattedMessage id="LbCreatedBy" defaultMessage="Created By" />,
        type: TDTypeConsts.CREATED_BY,
      },
      {
        key: "assignedEmployeeList",
        name: (
          <FormattedMessage
            id="LbAssignedEmployee"
            defaultMessage="Assigned Employee"
          />
        ),
        type: TDTypeConsts.ASSIGNED_USER,
      },
      // {key: 'teamheadname',  name: 'Part of Business Unit', type: TDTypeConsts.OTHERS},
      // {key: 'addedbyname',  name: 'Department Head', type: TDTypeConsts.OTHERS},
      {
        key: "edit",
        name: "",
        type: TDTypeConsts.EDIT_WITHOUT_HEADER,
      },
      {
        key: "delete",
        name: "",
        type: TDTypeConsts.DELETE_WITHOUT_HEADER,
      },
    ];
    this.listLenght = 0;
    this.doEditId = -1;
    this.updateSteps = this.updateSteps.bind(this);
    this.updatePostData = this.updatePostData.bind(this);
    this.setStep = this.setStep.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onCreatemanuClose = this.onCreatemanuClose.bind(this);
  }

  async componentDidMount() {
    this.props.ChangeLanguage("designation");
    const [isTrue, permissions] = CheckActionPermission("designation");

    if (isTrue) {
      this.setState({ permissions }, () => {
        this.updateSteps(true);
        this.props.getDesignationList();
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.EDIT_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
        }
        if (!this.state.permissions.editable) {
          const index = this.headers.findIndex(
            (x) => x.type == TDTypeConsts.DELETE_WITHOUT_HEADER,
          );
          if (index != -1) {
            this.headers.splice(index, 1);
          }
        }
      });
      await this.getDesignationData();
    } else {
      this.setState({ permissiondenied: true });
    }
  }

  onCreatemanuClose() {
    this.setState({
      createmenu: false,
      create_anchorEl: null,
    });
  }

  updateSteps(flag) {
    const { match } = this.props;
    // const { postData, allData } = this.state;
    const { postData, initData, allData } = this.state;
    const { currentEditData, subscriptionid, designationList } = this.props;

    const AllStep = [
      {
        component: (
          <Details
            onRef={(ref) => this.setState({ child0: ref })}
            isedit={true}
            initData={initData}
            postData={postData}
            updatePostData={this.updatePostData}
            submit={this.submit}
            {...this.props}
            {...this.state}
            subscriptionid={subscriptionid}
            currentEditData={currentEditData}
            onNextClick={this.onNextClick}
            setStep={this.setStep}
            onCancel={this.onCancel}
            allData={this.state.allData}
          />
        ),
        iserror: false,
        label: "Job title",
        step: 1,
      },
      {
        component: (
          <JobDetails
            // onRef={(ref) => this.setState({ child1: ref })}
            onRef={(ref) =>
              this.setState({
                child1: {
                  ...ref,
                  onCancel: this.onCancel,
                  onPrev: this.onPrevClick,
                  nextButtonLabel: "Save",
                },
              })
            }
            isedit={true}
            initData={initData}
            postData={postData}
            updatePostData={this.updatePostData}
            submit={this.submit}
            {...this.props}
            subscriptionid={subscriptionid}
            currentEditData={currentEditData}
            onNextClick={this.onNextClick}
            setStep={this.setStep}
            onCancel={this.onCancel}
          />
        ),
        iserror: false,
        label: "Job details",
        step: 2,
      },
    ];

    this.setState({ AllStep }, () => {
      if (!flag) {
        this.props.history.push(`${match.path}/create`);
      }
    });
  }

  getText() {
    return (
      <span>
        <FormattedMessage
          id="DesignationConcept"
          defaultMessage="Designations, also known as 'Job Titles' are assigned to employees to help them identify with their functional role in the organisation. Designations are also useful if you wish to create a hierarchical or reporting structure which gives clarity about the growth ladder in your company."
        />
      </span>
    );
  }

  _renderCreate = () => (
    <CreateComp
      text={this.getText()}
      heading={
        <FormattedMessage
          id="LbDesigantionConcept"
          defaultMessage="Concept of Designation"
        />
      }
      onCreate={this._onCreate}
      imageSrc={designationConcept}
      permissions={this.state.permissions}
    />
  );

  updatePostData(data) {
    this.setState({ postData: data }, () => this.updateSteps());
  }

  onCancel = () => {
    this.setState({ postData: POSTDATA }, () =>
      this.props.history.push("./list"),
    );
  };

  onNextClick = () => {
    if (this.child) {
      this.child.handleNext();
    }
  };

  onPrevClick = () => {
    if (this.child) {
      this.child.handleBack();
    }
  };

  getDesignationData() {
    const data = {
      featurekey: "designation",
    };
    instance
      .post("subscription/initdesignation", data)
      .then((result) => {
        this.setState({
          allData: result.data.result,
        });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  }

  showSnackbar = (msg, variant, autoHideDuration) => {
    this.props.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: autoHideDuration || 1000,
    });
  };

  _onCreate = () => {
    this.setState({ postData: POSTDATA , 
      createmenu: !this.state.createmenu,
      create_anchorEl: event.currentTarget, }, 
      //() => this._setEditPage()
      );
  };

  // _setEditPage = () => {
  //   const { match } = this.props;
  //   this.updateStepsData();
  //   this.props.history.push(`${match.path}/create`);
  // };

  _setEditPage = () => {
    this.setState({ 
      createmenu: !this.state.createmenu,
      create_anchorEl: null, });
    this.updateSteps();
  };

  // setStep(step, completed) {
  //   this.setState({ activeStep: step, completed });
  // }

  setStep(step, completed) {
    this.child.setState({ activeStep: step, completed });
    this.setState({ activeStep: step, completed });
  }

  onEdit = (item) => {
    instance
      .get(`subscription/designation/${item.designationid}`)
      .then((response) => {
        response.data.result[0].jobdetail = {
          mission: response.data.result[0].mission || null,
          rolesummary: response.data.result[0].rolesummary || null,
          responsibility: response.data.result[0].responsibility || null,
          skill: response.data.result[0].skill || [],
        };

        this.setState({ postData: response.data.result[0], createmenu: !this.state.createmenu,
          create_anchorEl: null}, () =>
          this._setEditPage(),
        );
      });
  };

  onDelete = (item) => {
    const { designationList } = this.props;
    const { list } = designationList;
    const id = item.designationid;
    const index = list.indexOf(item);
    instance.delete(`subscription/designation/${id}`).then(
      () => {
        list.splice(index, 1);
        this.showSnackbar("Designation successfully deleted.", "success");
        this.props.updateRespectiveList(ActionConstants.DESIGNATION, list);
        // have to reRender as custom action will not trigger re-render (reason typeToReducer)
        this.setState({ reRender: true }, () =>
          this.props.getDesignationList(),
        );
      },
      (error) => {
        const msg =
          error.response.data.error.message ||
          "Error while deleting Designation.";
        this.showSnackbar(msg, "error", 2000);
      },
    );
  };

  submit(data) {
    const { match } = this.props;
    delete data.mission;
    delete data.rolesummary;
    delete data.responsibility;
    delete data.skill;
    instance
      .post("subscription/designation", data)
      .then(() => {
        this.props.enqueueSnackbar("Saved successfully", {
          variant: "success",
        });
        // this.props.getProbationList();
        this.props.getDesignationList();
        this.getDesignationData();
        this.setState({ postData: POSTDATA });
        this.props.history.push(`${match.path}`);
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          error.response ? error.response.data.error.message : error.message,
          { variant: "error" },
        );
      });
  }

  onClickableNumberClick = (row, key, rowIndex) => {
    console.log("row, key, rowIndex: ", row, key, rowIndex);
    instance.get(`subscription/alldeparment/${row.designationid}`).then(
      (response) => {
        this.setState({ selectedDepartment: response.data.result }, () =>
          this.props.getDesignationList(),
        );
      },
      (error) => {
        const msg =
          error.response.data.error.message ||
          "Error while deleting Department.";
        this.showSnackbar(msg, "error", 2000);
      },
    );
  };

  _renderList = () => {
    const { designationList } = this.props;
    this.doEditId = -1;
    return (
      <List
        list={designationList.list}
        headers={this.headers}
        onCreate={this._onCreate}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        module={ActionConstants.DESIGNATION}
        goTo={this.props.goTo}
        permissions={this.state.permissions}
        onClickableNumberClick={this.onClickableNumberClick}
      />
    );
  };

  showLoader() {
    return <div className="pre-loader">Loading...</div>;
  }

  _renderComponent = () => {
    const { designationList } = this.props;
    if (designationList.IsPending) {
      return this.showLoader();
    }
    const language = this.props.Language.designation;
    return (
      <IntlProvider locale="en" messages={language}>
        <div>
          {designationList.list &&
            designationList.list.length == 0 &&
            this._renderCreate()}
          {designationList.list &&
            designationList.list.length > 0 &&
            this._renderList()}
        </div>
      </IntlProvider>
    );
  };

  getTabBasedForm = () => {
    const { postData, allData } = this.state;
    return (
      <div>
        <Details
          postData={postData}
          updatePostData={this.updatePostData}
          submit={this.submit}
          allData={allData}
          {...this.props}
        />
      </div>
    );
  };

  _renderForm = () => {
    const language = this.props.Language.department;
    return (
      <IntlProvider locale="en" messages={language}>
        <div className="config-table">{this.getTabBasedForm()}</div>
      </IntlProvider>
    );
  };

  render() {
    const { classes, match } = this.props;
    const { selectedDepartment, child0, child1 } = this.state;

    const language = this.props.Language.designation;
    return (
      <IntlProvider locale="en" messages={language}>
        <main className="content-outer-wrapper">
          <Helmet>
            <title>{headersList.Config.Designation.header}</title>
          </Helmet>
          {this.state.permissiondenied ? (
            <div className="position-rel">
              <PermissionDeniedComp />
            </div>
          ) : (
            <div>
              {this.props.location.pathname ===
              "/config/hris/company/designation/create" ? (
                <div>
                  <Stepper
                    AllStep={this.state.AllStep}
                    onRef={(ref) => (this.child = ref)}
                    activeStep={this.state.activeStep}
                    setStep={this.setStep}
                    completed={this.state.completed}
                    childs={[child0, child1]}
                  />
                </div>
              ) : (
                <Grid
                  container
                  className="business-config-layout-container probation-plan-page"
                >
                  <Grid item xs={12} md={12} sm={12}>
                    <Switch>
                      {/* <Route path={`${match.path}/create`} render={this._renderForm} /> */}
                      <Route
                        path={`${match.path}`}
                        render={this._renderComponent}
                      />
                      <Redirect from={`${match.path}`} to={`${match.path}`} />
                    </Switch>
          <Popover
          open={this.state.createmenu}
          anchorEl={this.state.create_anchorEl}
          // anchorOrigin={{
          //  // vertical: "bottom",
          //   horizontal: "right",
          // }}
          // transformOrigin={{
          //   vertical: "top",
          //   //horizontal: "right",
          // }}
          style={{top:"110px",
          left:"1235px",}}
          onClick={this.onCreatemanuClose}
          className="margin-top-8"
          >
              <Paper
                style={{
                  width: 300,
                  maxHeight: 400,
                  overflowY: "auto",
                  
                }}
              >
                <List1>
                    <ListItem
                      button
                      className="padding-top-4 padding-bottom-4"
                      onClick={() => {
                       
                        this._setEditPage();
                      }}
                    >
                      <ListItemText>
                        <p>Create</p>
                      </ListItemText>
                    </ListItem>
                  <Link
                    to={`../../../../live_data/designation`}
                    target="_blank"
                  >
                  <ListItem
                    button
                    className="padding-top-4 padding-bottom-4"
                  >
                    <ListItemText>
                      <p>Create Bulk</p>
                    </ListItemText>
                  </ListItem>
                  </Link>
                </List1>
              </Paper>
          </Popover>
                  </Grid>
                </Grid>
              )}

              <Drawer
                anchor="right"
                open={selectedDepartment}
                onClose={() => this.setState({ selectedDepartment: "" })}
                classes={{
                  paper: "drawer-main",
                }}
              >
                <div className="planner-right-drawer">
                  <DrawerHeader
                    header="Departments"
                    closeDrawer={() =>
                      this.setState({ selectedDepartment: "" })
                    }
                  />
                  {selectedDepartment &&
                    selectedDepartment.map((dep) => (
                      <p className="sub-heading">
                        <strong>{dep.departmentname}</strong>
                      </p>
                    ))}
                </div>
              </Drawer>
            </div>
          )}
        </main>
      </IntlProvider>
    );
  }
}

Index.propTypes = {};
const mapStateToProps = (state) => ({
  Language: state.LanguageAction.Language,
  designationList: getBusinessConfig(state, ActionConstants.DESIGNATION),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDesignationList,
      updateRespectiveList,
      ChangeLanguage: (data) => ChangeLanguage(data),
    },
    dispatch,
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(withSnackbar(Index)));
