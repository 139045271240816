import get from "lodash/get";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import List from "../../presentation/list";
import { reloadPopup } from "../../../../../../../Actions/commonAction";

class ListWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: props.headers,
      headerIndex: props.headerIndex || 0,
      orderBy: props.orderBy || "sortableDate",
      order: props.sortOrder || "asc",
      // list: props.list,
      list: [],
      originalList: props.list,
    };
  }

  componentWillReceiveProps(nextState) {
    this.setState(
      {
        // list: nextState.list,
        headers: nextState.headers,
        originalList: nextState.list,
        order: nextState.sortOrder || "asc",
        orderBy: nextState.orderBy || "sortableDate",
      },
      () => {
        this.AddSortableDate(nextState);
      },
    );
  }

  componentDidMount() {
    this.AddSortableDate(this.props);
  }

  AddSortableDate = (props) => {
    get(props, "list", []).forEach((item) => {
      const date = new Date(item.addeddate);
      if (date == "Invalid Date") {
        item.sortableDate = new Date().getTime();
      } else {
        item.sortableDate = date.getTime();
      }
    });

    this.setState({ list: props.list });
  };

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array = [], cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  }

  handleSort = (property) => {
    // console.log(property, event, 'onsort');
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    const list = this.stableSort(
      this.state.list,
      this.getSorting(order, orderBy),
    );
    this.setState({ order, orderBy, list });
  };

  onSearch = (value) => {
    const { headerIndex, headers, originalList } = this.state;
    const { key } = headers[headerIndex];
    const list = originalList.filter((item) => item[key].toLowerCase().includes(value));
    const isEmptyList = list && list.length > 0 ? false : true;
    this.setState({ list, isEmptyList });
  };

  onDeleteConfirmationBox = (item) => {
    this.props.enqueueSnackbar("Are you sure you want to delete?", {
      variant: "warning",
      action: (
        <span>
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              this.props.closeSnackbar();
              this.doDelete(item);
            }}
          >
            Delete
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={() => this.props.closeSnackbar()}
          >
            Dismiss
          </Button>
        </span>
      ),
    });
  };

  doDelete = (item) => {
    const { onDelete } = this.props;
    if (onDelete) onDelete(item);
  };

  _onDelete = (item) => {
    this.onDeleteConfirmationBox(item);
  };

  _goTo = (empId) => {
    const { goTo } = this.props;
    goTo(`/profile/${empId}`);
    return false;
  };

  render() {
    // const {
    // headers = [],
    // onDelete,
    //  list,
    // ...otherProps
    // } = this.props;
    return (
      <List
        {...this.props}
        headers={this.state.headers}
        orderBy={this.state.orderBy}
        order={this.state.order}
        handleSort={this.handleSort}
        list={this.state.list}
        onSearch={this.onSearch}
        onDelete={this._onDelete}
        goTo={this._goTo}
        permissions={this.props.permissions}
        isEmptyList = {this.state.isEmptyList}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      reloadPopup,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(withSnackbar(ListWrapper));
