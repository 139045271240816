export const InitialData = {
  attendancepolicyid: null,
  parentid: null,
  attendancepolicyname: null,
  description: null,
  iswfh: false,
  wfhlimit: 0,
  iswfo: true,
  wfolimit: 99,
  isoutduty: false,
  outdutylimit: 0,
  isrestrictattendancereq: false,
  restrictattendancereqlimit:null,
  isabsenteeismrule: false,
  absenteeism: {
    absenteeismtypeid: 0,
    absenteeismactionid: 0,
    absenteeismleaveid: 0,
    absenteeismleavename: null /* Add this field while creating new or editing */,
    absenteeismleavecount: 0,
  },
  ispunctualityrule: false,
  punctuality: {
    exceedinggracetypeid: null,
    punctualitytypeid: null,
    gracehourtime: null,
    penaltytypeid: 0,
    penaltyleaveid: 0,
    penaltyleavename: null /* Add this field while creating new or editing */,
    penaltyleavecount: null,
    penaltyactiontypeid: 0,
    gracedaycount: 0,
    gracetime: null,
    gracetimelimit: null,
    exceedgracedaycount: 0,
    breachleavecount: 0,
  },
  istimeatworkrule: false,
  timeatwork: {
    isforday: false,
    isforhalfday: false,
    halfdayinhours: null,
    halfdayinpercentage: null,
    hoursweekly: 0,
    minutesweekly: 0,
    ishalfdaypenalty: false,
    ishalfdayabsentpenalty:false,
    halfdayleaveid: null,
    halfdayleavename: null /* Add this field while creating new or editing */,
    halfdayleavecount: 0,
    halfdaypenaltyactiontypeid: null,
    isforfullday: false,
    fulldayinhours: null,
    fulldayinpercentage: null,
    isfulldaypenalty: false,
    isfulldayabsentpenalty:false,
    fulldayleaveid: null,
    fulldayleavename: null /* Add this field while creating new or editing */,
    fulldayleavecount: 0,
    fulldaypenaltyactiontypeid: null,
    isforweekly: false,
    weeklyhours: null,
    isweeklypenalty: false,
    weeklyleaveid: null,
    weeklyleavename: null /* Add this field while creating new or editing */,
    weeklyleavecount: 0,
    weeklypenaltyactiontypeid: null,
    isformonthly: false,
    monthlyhours: null,
    ismonthlypenalty: false,
    monthlyleaveid: null,
    monthlyleavecount: 0,
    monthlypenaltyactiontypeid: null,
  },
  isworkrequest: false,
  isdraft: true,
};
export const AttendanceInitialData = {
  attendancepolicyid: null,
  parentid: null,
  attendancepolicyname: null,
  description: null,
  iswfh: false,
  wfhlimit: 0,
  iswfo: true,
  wfolimit: 99,
  isoutduty: false,
  outdutylimit: 0,
  isabsenteeismrule: false,
  absenteeism: {
    absenteeismtypeid: 0,
    absenteeismactionid: 0,
    absenteeismleaveid: 0,
    absenteeismleavename: null /* Add this field while creating new or editing */,
    absenteeismleavecount: 0,
  },
  ispunctualityrule: false,
  punctuality: {
    exceedinggracetypeid: null,
    punctualitytypeid: null,
    gracehourtime: null,
    penaltytypeid: 0,
    penaltyleaveid: 0,
    penaltyleavename: null /* Add this field while creating new or editing */,
    penaltyleavecount: null,
    penaltyactiontypeid: 0,
    gracedaycount: 0,
    gracetime: null,
    gracetimelimit: null,
    exceedgracedaycount: 0,
    breachleavecount: 0,
  },
  istimeatworkrule: false,
  timeatwork: {
    isforday: false,
    isforhalfday: false,
    halfdayinhours: null,
    halfdayinpercentage: null,
    hoursweekly: 0,
    minutesweekly: 0,
    ishalfdaypenalty: false,
    halfdayleaveid: null,
    halfdayleavename: null /* Add this field while creating new or editing */,
    halfdayleavecount: 0,
    halfdaypenaltyactiontypeid: null,
    isforfullday: false,
    fulldayinhours: null,
    fulldayinpercentage: null,
    isfulldaypenalty: false,
    fulldayleaveid: null,
    fulldayleavename: null /* Add this field while creating new or editing */,
    fulldayleavecount: 0,
    fulldaypenaltyactiontypeid: null,
    isforweekly: false,
    weeklyhours: null,
    isweeklypenalty: false,
    weeklyleaveid: null,
    weeklyleavename: null /* Add this field while creating new or editing */,
    weeklyleavecount: 0,
    weeklypenaltyactiontypeid: null,
    isformonthly: false,
    monthlyhours: null,
    ismonthlypenalty: false,
    monthlyleaveid: null,
    monthlyleavecount: 0,
    monthlypenaltyactiontypeid: null,
  },
  isworkrequest: false,
  isdraft: true,
};
