// eslint-disable-next-line no-unused-vars
import Production from "./PROD.json";
import Development from "./DEV.json";
import Testing from "./TEST.json";

//export default Production;
//export default Development;
//export default Testing;

const env = process.env.REACT_APP_ENV || "TEST";

let config;

switch (env) {
  case "PROD":
    config = Production;
    break;
  case "DEV":
    config = Development;
    break;
  case "TEST":
  default:
    config = Testing;
    break;
}

export default config;
