import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import Card from "@material-ui/core/Card";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import If from "../Common/If";
import "./index.css";
import { ErrorMessages } from "Constants";

const styles = (theme) => ({
  root: {},
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: 60,
  },
});

class StepperComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      completed: props.completed || {},
      AllStep: props.AllStep || [],
    };
  }

  componentWillReceiveProps(props) {
    // const x = props.AllStep;
    // const y = this.state.AllStep;
    // const objectsEqual = (o1, o2) => typeof o1 === 'object' && Object.keys(o1).length > 0
    //   ? Object.keys(o1).length === Object.keys(o2).length
    //     && Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    //   : o1 === o2;
    // const arraysEqual = (arr1, arr2) => arr1.length === arr2.length && arr1.every((o, idx) => objectsEqual(o, arr2[idx]));
    // if (arraysEqual(props.AllStep, this.state.AllStep)) {
    //   this.setState({ AllStep: props.AllStep });
    // }
    // if (props.AllStep != this.state.AllStep) {
    //   this.setState({ AllStep: props.AllStep });
    // }
    // if (JSON.stringify(props.AllStep) != this.state.AllStep) {
    //   this.setState({ AllStep: props.AllStep });
    // }
    if (props.AllStep != this.state.AllStep) {
      this.setState({ AllStep: props.AllStep });
      if(this.props.biometricstepper) {
      if (props.AllStep && props.activeStep != this.state.activeStep) {
        this.setState({ activeStep: props.activeStep });
      }
    }
    }

   

    this.setState({ completed: props.completed || this.state.completed, });
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  handleNext = () => {
    let activeStep;
    const { completed } = this.state;
    if (this.isLastStep() && !this.allStepsCompleted()) {
      activeStep = this.state.AllStep.findIndex(
        (step, i) => !(i in this.state.completed),
      );
      completed[activeStep] = true;
    } else {
      completed[this.state.activeStep] = true;
      activeStep = this.state.activeStep + 1;
    }
    this.setState({ completed, activeStep }, () => {
      this.props.setStep(this.state.activeStep, this.state.completed);
    });
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleSkip = (step) => {
    this.setState((state) => ({
      activeStep: step == 1 ? state.activeStep + 1 : state.activeStep + 2,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: {},
    });
  };

  handleStep = (step) => () => {
    if (!this.state.completed[step]) {
      return false;
    }
    this.setState(
      {
        activeStep: step,
      },
      () => {
        this.props.setStep(this.state.activeStep, this.state.completed);
      },
    );
  };

  totalSteps = () => this.state.AllStep.length;

  completedSteps() {
    return Object.keys(this.state.completed).length;
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps();
  }

  getStepIcon = (index) => {
    const { activeStep, completed } = this.state;
    return (
      <span className="t-13">
        <If condition={activeStep == index}>
          <span className="current-step text-color-blue">{index + 1}</span>
        </If>
        <If condition={activeStep != index && completed[index]}>
          <span className="completed-step">{index + 1}</span>
        </If>
        <If condition={!(activeStep == index || completed[index])}>
          <span className="disabled-step">{index + 1}</span>
        </If>
      </span>
    );
  };

  getStepLabel = (index, label) => {
    const { activeStep, completed } = this.state;
    return (
      <span>
        <If condition={activeStep == index}>
          <span className="text-color-blue">{label}</span>
        </If>
        <If condition={activeStep != index && completed[index]}>
          <span className="">{label}</span>
        </If>
        <If condition={!(activeStep == index || completed[index])}>
          <span className="">{label}</span>
        </If>
      </span>
    );
  };

  render() {
    const { classes, closeSnackbar, childs = [] } = this.props;
    const { AllStep = [], activeStep, completed } = this.state;
    console.log("activeStep",activeStep);
    console.log("AllStep",AllStep);
    const c = (childs && childs.find((x, index) => index == activeStep)) || {};
    console.log("ccompo: ", c);
    // const ActiveComp = get(AllStep, `[${activeStep}].component`, <div></div>);
    const {
      onCancel = null,
      onCancel1 = null,
      onSaveDraft = null,
      onPrev = null,
      onNext = null, // it's also onsave
      onSkip = null,
      nextButtonLabel = "Next",
      skipButtonLabel = "Skip",
      noeditaccess
    } = c;

    return (
      <div className={classes.root}>
        <Card
          style={{
            padding: "0 20px",
            boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
          }}
        >
          <Stepper
            style={{ padding: "16px 0px 18px 0px" }}
            activeStep={activeStep}
            nonLinear
            alternativeLabel
          >
            {AllStep.map((step, index) => {
              const props = {};
              const labelProps = {};
              return (
                <Step
                  className={classNames(
                    index == this.state.activeStep ? "ActiveStep" : "",
                  )}
                  onClick={this.handleStep(index)}
                  key={index + 1}
                  {...props}
                >
                  <StepButton
                    disabled={!completed[index]}
                    icon={this.getStepIcon(index)}
                    completed={completed[index]}
                    style={{ padding: 16 }}
                  >
                    <StepLabel {...labelProps} className="stepper-tab">
                      {this.getStepLabel(index, step.label)}
                    </StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Card>

        {activeStep === AllStep.length ? null : (
          <div className="margin-top-24">
            <Typography className={classes.instructions}>
              {AllStep && AllStep[activeStep] && AllStep[activeStep].component && AllStep[activeStep].component}
            </Typography>
            {c && (
              <div className="width100">
                <div>
                  {c && (
                    <div className="stepper-action-wrapper">
                      <div>
                        <div className="stepper-left-action">
                          {onCancel && (
                            <Button
                              className="btn btn-trans margin-right-16"
                              variant="contained"
                              onClick={() => {
                                this.props.enqueueSnackbar(
                                  ErrorMessages.E0140,
                                  {
                                    variant: "warning",
                                    action: [
                                      <Button
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          onCancel();
                                          closeSnackbar();
                                        }}
                                      >
                                        Yes
                                      </Button>,
                                      <Button
                                        color="inherit"
                                        size="small"
                                        onClick={() => closeSnackbar()}
                                      >
                                        No
                                      </Button>,
                                    ],
                                  },
                                );
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                          {onCancel1 && (
                            <Button
                              className="btn btn-secondary-md-blue margin-right-16"
                              variant="contained"
                              onClick={onCancel1}
                            >
                              Cancel
                            </Button>
                          )}
                          {onSaveDraft && (
                            <Button
                              className="btn btn-secondary-md-blue margin-right-16"
                              variant="contained"
                              onClick={onSaveDraft}
                            >
                              Save as draft
                            </Button>
                          )}
                        </div>
                        <div className="stepper-right-action">
                          {onPrev && (
                            <Button
                              className="btn btn-secondary-md-blue margin-right-16"
                              variant="contained"
                              onClick={onPrev}
                            >
                              Previous
                            </Button>
                          )}
                          {onSkip && (
                            <Button
                              className="btn btn-primary-md-yellow margin-right-16"
                              variant="contained"
                              onClick={onSkip}
                            >
                              {skipButtonLabel}
                            </Button>
                          )}
                          {onNext && (
                            <Button
                              // className={
                              //   this.props.permissions &&
                              //   this.props.permissions.editable
                              //     ? "btn btn-primary-md-blue"
                              //     : "btn btn-primary-md-grey"
                              // }
                              className={noeditaccess ? "btn btn-primary-md-grey" : "btn btn-primary-md-blue" }
                              variant="contained"
                              onClick={onNext}
                              // disabled={
                              //   this.props.permissions &&
                              //   this.props.permissions.editable
                              //     ? false
                              //     : true
                              // }
                              
                              disabled={noeditaccess}
                            >
                              {nextButtonLabel}
                            </Button>
                          )}
                        </div>
                        <div className="clearfloat"></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(StepperComponent));
