import get from "lodash/get";
import moment from "moment";
import ProfileIcon from "../Assets/Icons/profile.png";
import sumhrFavicon from "../Assets/Images/sumhrFavicon.png";
import Configs from "../Constants/Configs";

export const getEJ2CalenderDateFormat = (format) => {
  // return "dd/MMMM/yyyy";

  let result = format;
  if (!format) {
    result = localStorage.getItem("dateformat")
      ? localStorage.getItem("dateformat")
      : "YYYY-MM-DD HH";
    const index = result.indexOf(" HH");
    result = result.slice(0, index);
    result = result.replace("Y", "y").replace("m", "M").replace("D", "d");
  } else {
    result = result.replace("Y", "y").replace("m", "M").replace("D", "d");
  }
  return result;
};

export function formatEJ2CalenderDate(date) {
  return date ? new Date(convertDateForBackend(date)) : null;
}

export const getEJ2CalenderMonthFormat = (format) => {
  let result = format;
  if (!format) {
    result = "mmm, YYYY";
    // result = localStorage.getItem("dateformat") ? localStorage.getItem("dateformat") : "YYYY-MM-DD";
    result = result.slice(0, 10);
    result = result.replace("Y", "y").replace("m", "M").replace("D", "d");
  } else {
    result = result.replace("Y", "y").replace("m", "M").replace("D", "d");
  }
  return result;
};

export const getFormattedDate = (date) => {
  //  console.log("date: ", date, "type", typeof date);
  if (date) {
    let dformat = "DD-MM-YYYY";

    const rawDate =
      typeof date === "object"
        ? date
        : date.includes("T")
        ? date.split("T")[0].split("-")
        : date.split(" ")[0].split("-");

    // console.log("rawDate: ", rawDate, "date.splitT", date.split("T"));

    const dateResult =
      typeof get(date, "getMonth", "") == "function"
        ? date
        : new Date(rawDate[0], rawDate[1] - 1, rawDate[2]);
    //  console.log("dateResult: ", dateResult);
    if (localStorage.getItem("dateformat")) {
      dformat = localStorage.getItem("dateformat");
      try {
        const dformat2 = dformat.split(" HH");
        const x = dformat2[0];
        if (dformat2[0]) {
          dformat = x;
        }
      } catch (er) {}
    }
    return moment(dateResult).format(dformat) == "Invalid date" ||
      moment(dateResult).format(dformat) == null ||
      moment(dateResult).format(dformat) == "null"
      ? ""
      : moment(dateResult).format(dformat);
  }
  return null;
};

export const getFormattedDaymonth = (date) => {
  if (date) {
    let dformat = "DD MMM";
    const dateResult =
      typeof get(date, "getMonth", "") == "function" ? date : new Date(date);
    if (localStorage.getItem("dateformat")) {
      dformat = localStorage.getItem("dateformat");
      try {
        const dformat2 = dformat.split(" HH");
        dformat = dformat2[0];
        if (dformat2[0]) {
          dformat = dformat.split("");
          dformat = dformat.filter((str) => str != "Y");
          dformat = dformat.join("");
          dformat = dformat.replace("M-", "M");
          dformat = dformat.replace("D,", "D");
        }
      } catch (er) {}
    }
    return moment(dateResult).format(dformat);
  }
  return null;
};

export const getFormattedDayMonthTime = (date) =>
  `${getFormattedDaymonth(date)} ${getFormattedTimeFromDate(date)}` || null;

export const getFormattedDateTime = (date) =>
  `${getFormattedDate(date)} ${getFormattedTimeFromDate(date)}` || null;
// if (date) {
//   let dformat = "DD-MM-YYYY hh:mm:ss";
//   const dateResult = typeof get(date, "getMonth", "") == "function" ? date : new Date(date);
//   if (localStorage.getItem("dateformat")) {
//     dformat = localStorage.getItem("dateformat");
//   }
//   return moment(dateResult).format(dformat);
// }
// return null;

export const getTime = (timeString) => {
  const H = +timeString.substr(0, 2);
  const h = H % 12 || 12;
  const ampm = H < 12 || H === 24 ? "AM" : "PM";
  return h + timeString.substr(2, 3) + ampm;
};

export const getFormattedMenuList = (rootmenus = [], features = []) => {
  const resultMenuIcons = [];
  let isConfigAvailable = false;

  const resultMenus = [
    ...rootmenus
      .map((rootmenu) => {
        const index = features.findIndex(
          (feature) => rootmenu.id === feature.featureid,
        );
        if (index != -1) {
          rootmenu = {
            ...rootmenu,
            ...features[index],
            value:
              rootmenu.value &&
              rootmenu.value
                .filter(
                  (navmenu) =>
                    features.findIndex(
                      (feature) => navmenu.id === feature.featureid,
                    ) != -1,
                )
                // .filter((navmenu) => features.filter((feature) => feature.parentmenuid == navmenu.id && feature.isconfigmode).length !== 0)
                .map((navmenu) => {
                  const i = features.findIndex(
                    (feature) => navmenu.id === feature.featureid,
                  );
                  if (i != -1) {
                    navmenu = {
                      ...navmenu,
                      ...features[i],
                    };
                  }
                  return navmenu;
                }),
          };
        }
        return rootmenu;
      })
      .filter((rootmenu) => get(rootmenu, "value[0]", false)),
  ];

  const resultMenus1 = resultMenus.filter(
    (rootmenu) =>
      features.findIndex((feature) => feature.parentmenuid == rootmenu.id) !=
      -1,
  );

  resultMenus1.forEach((rootmenu) => {
    rootmenu.value = rootmenu.value.filter(
      (navmenu) =>
        features.findIndex((feature) => feature.parentmenuid == navmenu.id) !=
        -1,
    );
  });

  const x = resultMenus1.map((rootmenu) => ({
    ...rootmenu,
    value:
      rootmenu.value &&
      rootmenu.value.map((navmenu) => ({
        ...navmenu,
        value:
          navmenu.value &&
          navmenu.value
            .filter((submenu) => {
              if (
                features.findIndex(
                  (feature) => submenu.id === feature.featureid,
                ) != -1
              ) {
                isConfigAvailable = true;
                return true;
              }
              return false;
            })
            .map((submenu) => {
              const index = features.findIndex(
                (feature) => submenu.id === feature.featureid,
              );
              if (index != -1) {
                submenu = {
                  ...submenu,
                  ...features[index],
                };
              }
              return submenu;
            }),
      })),
  }));

  return [isConfigAvailable, x, resultMenuIcons];
};

export const getFormattedImageLink = (link) => {
  const res =
    (link && link != "null" && `${Configs.STORE_URL}${link}`) || ProfileIcon;
  return res;
};

export const getFormattedLogoLink = (link) => {
  const res = (link && `${Configs.STORE_URL}${link}`) || sumhrFavicon;
  return res;
};

export const getFormattedTime = (time) => {
  const d1 = moment(time, "HH:mm:ss").format("HH:mm A");
  return d1;
};

export const getFormattedTimewithAll = (time, timeformat) => {
  const d1 = moment(time, "HH:mm:ss").format(
    `${timeformat.includes("12") ? "hh:mm A" : "HH:mm A"}`,
  );
  return d1;
};

export const parseDateExcel = (excelTimestamp) => {
  const secondsInDay = 24 * 60 * 60;
  const excelEpoch = new Date(1899, 11, 31);
  const excelEpochAsUnixTimestamp = excelEpoch.getTime();
  const missingLeapYearDay = secondsInDay * 1000;
  const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
  const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
  const parsed = excelTimestampAsUnixTimestamp + delta;
  return Number.isNaN(parsed) ? null : parsed;
};

export const convertExcelDateToTime = (time) => {
  try {
    let d1;
    let ampm;
    if (Number(time) == time) {
      d1 = moment.utc(time * 24 * 60 * 60 * 1000);
      ampm = time < 0.5 ? "AM" : "PM";
    } else if (time) {
      d1 = moment(time).utc();
      ampm = d1.date() == 30 ? "AM" : "PM";
    } else {
      return "";
    }
    d1 = d1.format("hh:mm Z");
    d1 = moment(d1, "hh:mm Z").utc().format("hh:mm");
    return d1 != "Invalid date" ? `${d1} ${ampm}` : "";
  } catch (err) {
    return "";
  }
};

export const convertExcelDateToDate = (time) => {
  if (time) {
    try {
      let d1;
      d1 = new Date(parseDateExcel(time));

      // d1 = moment(time).utc();
      // console.log("momentd1: ", d1);
      d1 = getFormattedDate(d1);

      return d1 != "Invalid date" ? d1 : "";
    } catch (err) {
      return "";
    }
  }
};

export const getFormattedTimeFromDate = (time) => {
  const d1 = moment(time, "YYYY-MM-DD hh:mm:ss").format("hh:mm A");
  return d1 != "Invalid date" ? d1 : "";
};

export const convertDateForBackend = (date) => {
  let res = null;
  const typeofdate = typeof date;
  if (typeofdate == "string") {
    date.replace("/", "-");
    date.replace(",", "-");
  }
  // console.log("date1: ", date);
  if (typeofdate == "object" && date) {
    res = moment(date).format("YYYY-MM-DD");
    //  console.log("res: ", res);
    return res == "Invalid date" ? null : res;
  } else if (typeofdate == "string" && date[4] == "-") {
    return date.trim().slice(0, 10);
  }
  const format = localStorage.getItem("dateformat")
    ? localStorage.getItem("dateformat")
    : "YYYY-MM-DD HH";
  const dformat2 = format.split(" HH");
  // console.log("date2: ", date);
  if (date) {
    res = moment(date, dformat2).format("YYYY-MM-DD");
    return res == "Invalid date" ? null : res;
  }
  return null;
};

export const convertDateTimeForBackend = (date) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
  return null;
};

export const isNumeric = (value) => value == Number(value);

export const convertTOBase64 = (value) => {
  const x =
    value &&
    window.btoa(unescape(encodeURIComponent(value.split("’").join("'"))));
  return x;
};

export const rawDataIntoExcel = (value) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  value.forEach((rowArray) => {
    let row = "";
    rowArray.forEach((x) => {
      x = x == null ? " " : x;
      if (
        typeof x === "string" &&
        x.toString().split(",") &&
        x.split(",").length > 1
      ) {
        x = `"${x}"`;
        row = `${row + x},`;
      } else {
        row = `${row + x},`;
      }
    });
    csvContent += `${row}\r\n`;
  });
  return csvContent;
};

export const fileToBase64Link = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getSelectValue = (arr = [], value) =>
  arr.find((x) => x.displayvalue == value);

// eslint-disable-next-line no-confusing-arrow
export const formatArrayOrSplitString = (str) =>
  typeof str == "string" ? str.split(",") : Array.isArray(str) ? str : [];

const ext = "7z";

export const getDocTypeid = {
  jpg: 1,
  jpeg: 1,
  png: 1,
  tif: 1,
  tiff: 1,
  gif: 1,
  avi: 2,
  flv: 2,
  mkv: 2,
  mov: 2,
  mp4: 2,
  webm: 2,
  wmv: 2,
  wav: 3,
  mp3: 3,
  aac: 3,
  ogg: 3,
  wma: 3,
  flac: 3,
  doc: 4,
  docx: 4,
  odt: 4,
  pdf: 4,
  xls: 4,
  xlsx: 4,
  csv: 4,
  ods: 4,
  ppt: 4,
  pptx: 4,
  key: 4,
  txt: 4,
  zip: 5,
  gzip: 5,
  rar: 5,
  [ext]: 5,
  apk: 5,
  ipa: 5,
};

export const getDocFormatForApi = (extstr) => {
  if (getDocTypeid[extstr] == 1) {
    return `image/${extstr}`;
  } else if (getDocTypeid[extstr] == 2) {
    return `video/${extstr}`;
  } else if (getDocTypeid[extstr] == 3) {
    return `audio/${extstr}`;
  } else if (getDocTypeid[extstr] == 4) {
    return `application/${extstr}`;
  } else if (getDocTypeid[extstr] == 5) {
    return `compressed/${extstr}`;
  }
};

export function isAlphaNumeric(str) {
  return /^[a-z0-9]+$/.test(str);
}

export function isDeptDesigName(str) {
  return /^[{a-zA-Z0-9}\d\_\-\s\/]+$/.test(str);
}

export const months = [
  {
    displayvalue: 1,
    displayname: "January",
  },
  {
    displayvalue: 2,
    displayname: "February",
  },
  {
    displayvalue: 3,
    displayname: "March",
  },
  {
    displayvalue: 4,
    displayname: "April",
  },
  {
    displayvalue: 5,
    displayname: "May",
  },
  {
    displayvalue: 6,
    displayname: "June",
  },
  {
    displayvalue: 7,
    displayname: "July",
  },
  {
    displayvalue: 8,
    displayname: "August",
  },
  {
    displayvalue: 9,
    displayname: "September",
  },
  {
    displayvalue: 10,
    displayname: "October",
  },
  {
    displayvalue: 11,
    displayname: "November",
  },
  {
    displayvalue: 12,
    displayname: "December",
  },
];

export const shortmonths = [
  { id: 1, name: "Jan" },
  { id: 2, name: "Feb" },
  { id: 3, name: "Mar" },
  { id: 4, name: "Apr" },
  { id: 5, name: "May" },
  { id: 6, name: "Jun" },
  { id: 7, name: "Jul" },
  { id: 8, name: "Aug" },
  { id: 9, name: "Sep" },
  { id: 10, name: "Oct" },
  { id: 11, name: "Nov" },
  { id: 12, name: "Dec" },
];

export const getMonthName = (monthid = 1) =>
  monthid && months.find((x) => x.displayvalue == monthid).displayname;


  export function isValidEmailId(str) {
    return /^\w+([\.-]?\w+)*([+]{0,1}\w+){0,1}@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(str);
  }

  export function isSpaceInString(str) {
    return /^(?!\s).+(?<!\s)$/gm.test(str);
  }

  export function isSpecialChracterIncluded(str) {
    return /[’;?:@=+$,#*^{}[]|”"<>\\]/.test(str);
  }
  
